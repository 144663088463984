import {Component, OnInit, ViewChild} from '@angular/core';

import {IonContent, ModalController} from '@ionic/angular';
import {DisplayMode} from '../../../enums/DisplayMode';
import {MenuExtraPage} from '../../pages/menu-extra/menu-extra.page';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {
	defaultsToArticleOption,
	getPrice,
	numberToCurrency
} from 'src/smoothr-web-app-core/utils/utils';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import GroupDependency from '../../../smoothr-web-app-core/models/GroupDependency';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import {filterMatchingOptions} from 'src/smoothr-web-app-core/utils/order-utils';

@Component({
	selector: 'app-article-details-modal',
	templateUrl: './article-details-modal.component.html',
	styleUrls: ['./article-details-modal.component.scss']
})
export class ArticleDetailsModalComponent implements OnInit {
	article: Article;
	venue: Venue;
	indexInOrder = -1;
	redirectToOrder = false;
	currency;

	@ViewChild(IonContent, {static: false}) content: IonContent;
	selectedOptions: ArticleOption[] = [];
	isValid = false;

	dm = DisplayMode;
	isGroupDependencyFulfilled = ValidationUtils.isGroupDependencyFulfilled;
	filterMatchingOptions = filterMatchingOptions;
	constructor(
		private repository: RepositoryService,
		private modalCtrl: ModalController,
		private router: Router,
		private snackbarCtrl: MatSnackBar
	) {}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		article: Article,
		currency: string
	): Promise<ArticleGroup> {
		const options =
			article.recommendations.length > 0
				? article.recommendations
				: article.defaults;
		const modal = await modalCtrl.create({
			component: ArticleDetailsModalComponent,
			cssClass: 'article-details-modal',
			componentProps: {
				venue,
				article,
				selectedOptions: defaultsToArticleOption(
					article,
					[],
					options,
					PreorderType.INSIDE,
					null,
					true
				),
				currency
			}
		});
		await modal.present();
		const detail = await modal.onDidDismiss();
		if (detail != null && detail.data != null) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = detail.data.article;
			articleGroup.groups = detail.data.options;
			articleGroup.quantity = 1;
			return articleGroup;
		}
		return null;
	}

	static async edit(
		modalCtrl: ModalController,
		article: Article,
		selectedOptions: ArticleOption[],
		currency: string,
		indexInOrder: number,
		redirectToOrder: boolean
	): Promise<ArticleGroup> {
		const modal = await modalCtrl.create({
			component: ArticleDetailsModalComponent,
			cssClass: 'article-details-modal',
			componentProps: {
				article,
				selectedOptions: [...selectedOptions],
				currency,
				indexInOrder,
				redirectToOrder
			}
		});
		await modal.present();
		const detail = await modal.onDidDismiss();
		if (detail != null && detail.data != null) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = detail.data.article;
			articleGroup.groups = detail.data.options;
			articleGroup.quantity = 1;
			return articleGroup;
		}
		return null;
	}

	ngOnInit() {
		this.isValid = ValidationUtils.validate(
			this.venue,
			this.article,
			this.selectedOptions,
			[DisplayIdentifier.menu]
		);
	}

	addSingle(option: ArticleOption) {
		const index = this.selectedOptions.findIndex(
			value => value.group === option.group
		);
		if (index >= 0) {
			this.selectedOptions[index] = option;
		} else {
			this.selectedOptions.push(option);
		}
		this.isValid = ValidationUtils.validate(
			this.venue,
			this.article,
			this.selectedOptions,
			[DisplayIdentifier.menu]
		);
		this.sanitizeSelectedOptions();
	}

	add(
		option: ArticleOption,
		group: OptionGroup,
		times: number,
		dependsOn: string,
		dependency: GroupDependency
	) {
		if (times > 0) {
			option.dependencyNumber = times;
			option.dependsOn = dependsOn;
			option.dependency = dependency._id;
		}
		if (group.limit === 1) {
			this.addSingle(option);
			return;
		}
		const selection = this.selectedOptions.filter(value => {
			return (
				option.group === value.group &&
				option.dependencyNumber === value.dependencyNumber &&
				option.dependsOn === value.dependsOn &&
				option.dependency === value.dependency
			);
		});
		const count = selection
			.map(value => value.quantity)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
		if (count >= group.limit) {
			const indexOfFirst = this.selectedOptions.indexOf(
				selection.find(value => value.article !== option.article)
			);
			if (indexOfFirst >= 0) {
				this.selectedOptions[indexOfFirst].quantity--;
				if (this.selectedOptions[indexOfFirst].quantity <= 0) {
					this.selectedOptions.splice(indexOfFirst, 1);
				}
			} else {
				// Article already selected max times and selected again
				this.add(option, group, times, dependsOn, dependency);
				return;
			}
		}
		const index = this.selectedOptions.findIndex(
			value => value.article === option.article
		);
		if (index >= 0) {
			this.selectedOptions[index].quantity++;
		} else {
			this.selectedOptions.push(option);
		}
		this.isValid = ValidationUtils.validate(
			this.venue,
			this.article,
			this.selectedOptions,
			[DisplayIdentifier.menu]
		);
		this.sanitizeSelectedOptions();
	}

	sanitizeSelectedOptions() {
		this.selectedOptions = this.selectedOptions.filter(it => {
			return (
				this.isGroupDependencyFulfilled(
					this.article,
					this.selectedOptions,
					this.relevantOptionGroups().find(og => og._id === it.group)
				).times >= 0
			);
		});
		defaultsToArticleOption(
			this.article,
			this.selectedOptions,
			this.article.recommendations.length > 0
				? this.article.recommendations
				: this.article.defaults,
			PreorderType.INSIDE,
			null,
			true
		)
			.filter(it => {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = this.selectedOptions;
				return (
					it.dependencyNumber > 0 &&
					!ValidationUtils.areGroupsValid(articleGroup, [
						this.relevantOptionGroups().find(og => og._id === it.group)
					])
				);
			})
			.forEach(it => {
				console.log(it);
				this.selectedOptions.push(it);
			});
	}

	totalPrice() {
		let price = getPrice(
			this.article,
			OrderType.PREORDER,
			PreorderType.INSIDE,
			null
		);
		if (this.selectedOptions.length > 0) {
			price =
				price +
				this.selectedOptions
					.map(option => {
						return (
							getPrice(
								option.article,
								OrderType.PREORDER,
								PreorderType.INSIDE,
								null
							) * option.quantity
						);
					})
					.reduce((prev, curr) => prev + curr);
		}
		return numberToCurrency(price, this.currency);
	}

	relevantOptionGroups(): OptionGroup[] {
		return this.article.groups.filter(
			value =>
				value.displayIdentifiers == null ||
				value.displayIdentifiers.indexOf(DisplayIdentifier.menu) < 0
		);
	}

	async confirm() {
		this.selectedOptions = this.selectedOptions.filter(articleOption => {
			return (
				ValidationUtils.isGroupDependencyFulfilled(
					this.article,
					this.selectedOptions,
					this.article.groups.find(group => group._id === articleOption.group)
				).times >= 0
			);
		});
		this.selectedOptions.forEach(articleOption => {
			if (articleOption.dependsOn != null) {
				const index = this.selectedOptions.findIndex(value => {
					return (
						value.article._id === articleOption.dependsOn &&
						(value.dependencyNumber === undefined ||
							value.dependencyNumber === articleOption.dependencyNumber)
					);
				});
				if (index >= 0) {
					this.selectedOptions[index].dependencyNumber =
						articleOption.dependencyNumber;
				}
			}
		});
		this.isValid = ValidationUtils.validate(
			this.venue,
			this.article,
			this.selectedOptions,
			[DisplayIdentifier.menu]
		);
		const menuGroup = this.article.groups.filter(
			value =>
				value.displayIdentifiers !== null &&
				value.displayIdentifiers.indexOf(DisplayIdentifier.menu) >= 0
		);

		const mapArticleGroupsArray = new Map(
			this.article.groups.map(it => [it._id, it])
		);
		this.selectedOptions = this.selectedOptions.sort(
			(a, b) =>
				mapArticleGroupsArray.get(a?.group)?.sortOrder -
				mapArticleGroupsArray.get(b?.group)?.sortOrder
		);
		if (this.isValid) {
			if (menuGroup.length > 0) {
				await this.modalCtrl.dismiss();
				await MenuExtraPage.navigate(
					this.router,
					this.article,
					menuGroup[0],
					this.selectedOptions,
					this.indexInOrder,
					this.redirectToOrder
				);
			} else {
				await this.modalCtrl.dismiss({
					article: this.article,
					options: this.selectedOptions,
					indexInOrder: this.indexInOrder,
					redirectToOrder: this.redirectToOrder
				});
			}
		} else {
			this.snackbarCtrl.open('Vergessen etwas auszusuchen?', null, {
				duration: 2000
			});
		}
	}

	async close() {
		await this.modalCtrl.dismiss();
	}

	replaceOptions(
		optionGroup: OptionGroup,
		articleOptions: ArticleOption[],
		times: number,
		dependsOn: string,
		dependency: GroupDependency
	) {
		if (times > 0) {
			articleOptions.map(value => {
				value.dependencyNumber = times;
				value.dependsOn = dependsOn;
				value.dependency = dependency._id;
			});
		}
		this.selectedOptions = this.selectedOptions.filter(
			articleOption => articleOption.group !== optionGroup._id
		);
		this.selectedOptions.push(...articleOptions);
		this.isValid = ValidationUtils.validate(
			this.venue,
			this.article,
			this.selectedOptions,
			[DisplayIdentifier.menu]
		);
	}

	scrollTo(event) {}
}
