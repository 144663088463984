import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from '../components/toolbar/toolbar.component';
import {ToolbarStepComponent} from '../components/toolbar-step/toolbar-step.component';
import {VenuePickerModalComponent} from '../components/venue-picker-modal/venue-picker-modal.component';
import {IonicModule} from '@ionic/angular';
import {BannerArticleListComponent} from '../components/banner-article-list/banner-article-list.component';
import {ArticleDetailsModalComponent} from '../components/article-details-modal/article-details-modal.component';
import {ArticleOptionGroupDrinkFlavorComponent} from '../components/article-option-group-dink-flavor/article-option-group-drink-flavor.component';
import {ArticleOptionGroupFlavorComponent} from '../components/article-option-group-flavor/article-option-group-flavor.component';
import {ItemSelectableArticleComponent} from '../components/item-selectable-article/item-selectable-article.component';
import {ToOrderButtonComponent} from '../components/to-order-button/to-order-button.component';
import {OrderListComponent} from '../components/order-list/order-list.component';
import {ToggleButtonGroupComponent} from '../components/toggle-button-group/toggle-button-group.component';
import {SlotsModalComponent} from '../components/slots-modal/slots-modal.component';
import {CategoryNavigatorComponent} from '../components/category-navigator/category-navigator.component';
import {ArticleListComponent} from '../components/article-list/article-list.component';
import {MenuPopoverComponent} from '../components/menu-popover/menu-popover.component';
import {FormsModule} from '@angular/forms';
// import {CalendarModule} from 'ion2-calendar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ArticleOptionGroupMatrixComponent} from '../components/article-option-group-matrix/article-option-group-matrix.component';
import {LoginOrderModalComponent} from '../components/login-order-modal/login-order-modal.component';
import {MyOrderComponent} from '../components/my-order/my-order.component';
import {OrderModalComponent} from '../components/order-modal/order-modal.component';
import {QRCodeModule} from 'angular2-qrcode';
import {PushModalComponent} from '../components/push-modal/push-modal.component';
import {DeleteCartItemModalComponent} from '../components/delete-cart-item-modal/delete-cart-item-modal.component';
import {HttpClient} from '@angular/common/http';
import {createTranslateLoader} from '../app.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {OrderContentComponent} from '../components/order-content/order-content.component';
import {CheckoutModalComponent} from '../components/checkout-modal/checkout-modal.component';
import {SlotsSelectorComponent} from '../components/slots-selector/slots-selector.component';
import {OrderPageToolbarComponent} from '../components/order-page-toolbar/order-page-toolbar.component';
import {PaymentModalComponent} from '../../smoothr-web-app-core/components/payment-modal/payment-modal.component';

@NgModule({
	declarations: [
		PaymentModalComponent,
		VenuePickerModalComponent,
		ArticleDetailsModalComponent,
		ToolbarComponent,
		ToolbarStepComponent,
		BannerArticleListComponent,
		ArticleOptionGroupDrinkFlavorComponent,
		ArticleOptionGroupFlavorComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		SlotsModalComponent,
		CategoryNavigatorComponent,
		ArticleListComponent,
		MenuPopoverComponent,
		LoginOrderModalComponent,
		OrderModalComponent,
		ArticleOptionGroupMatrixComponent,
		ArticleOptionGroupMatrixComponent,
		MyOrderComponent,
		PushModalComponent,
		DeleteCartItemModalComponent,
		CheckoutModalComponent,
		OrderContentComponent,
		SlotsSelectorComponent,
		OrderPageToolbarComponent
	],
	exports: [
		VenuePickerModalComponent,
		LoginOrderModalComponent,
		ArticleDetailsModalComponent,
		ToolbarComponent,
		ToolbarStepComponent,
		BannerArticleListComponent,
		ArticleOptionGroupDrinkFlavorComponent,
		ArticleOptionGroupFlavorComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		SlotsModalComponent,
		OrderModalComponent,
		CategoryNavigatorComponent,
		ArticleListComponent,
		MenuPopoverComponent,
		ArticleOptionGroupMatrixComponent,
		MyOrderComponent,
		PushModalComponent,
		DeleteCartItemModalComponent,
		TranslateModule,
		PaymentModalComponent,
		OrderContentComponent,
		CheckoutModalComponent,
		SlotsSelectorComponent,
		OrderPageToolbarComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		// CalendarModule,
		FormsModule,

		MatSnackBarModule,
		QRCodeModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	]
})
export class SharedModuleModule {}
