import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';
import {CategoryPage} from '../category/category.page';
import {OrderContentComponent} from '../../components/order-content/order-content.component';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['./order.page.scss']
})
export class OrderPage implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';

	@ViewChild(OrderContentComponent, {static: true}) orderContent;

	constructor(
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	static navigate(
		router: Router,
		replaceUrl: boolean = false,
		openCheckout: boolean = false
	) {
		if (AppComponent.largeScreen) {
			return CategoryPage.navigate(router, null, openCheckout);
		}
		return router.navigateByUrl(OrderPage.url, {
			replaceUrl,
			state: {
				openCheckout
			}
		});
	}

	ionViewDidEnter() {
		if (history.state.openCheckout) {
			this.orderContent.openCheckoutModal();
			history.state.openCheckout = undefined;
		}
	}

	ngOnInit(): void {
		switch (this.router.url.split('?')[0]) {
			case '/order':
				break;
			case '/payment/cancel':
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_cancel'),
					null,
					{
						duration: 2000
					}
				);
				if (AppComponent.largeScreen) {
					CategoryPage.navigate(this.router);
				}
				break;
			case '/payment/fail':
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_fail'),
					null,
					{
						duration: 5000
					}
				);
				if (AppComponent.largeScreen) {
					CategoryPage.navigate(this.router);
				}
				break;
		}
	}
}
