import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MaintenancePage} from './pages/maintenance-page/maintenance.page';
import {SwUpdate} from '@angular/service-worker';
import 'firebase/analytics';
import {AnalyticsService} from '../smoothr-web-app-core/services/analytics/analytics.service';
import moment from 'moment';
import {Api} from '../smoothr-web-app-core/api/api';
import {axiosErrorToMessage} from '../smoothr-web-app-core/utils/utils';

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;

	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackBarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AnalyticsService,
		platform: Platform,
		private translate: TranslateService,
		private swUpdate: SwUpdate
	) {
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);
			await this.handleCookieConsent();
		};
		console.log(environment.customerGroup + ' ' + environment.version);
		analytics.logVersion();
		translate.setDefaultLang('de');
		translate.use('de');
		moment.locale('de');

		this.initCookieConsent();

		swUpdate.available.subscribe(() => {
			const snack = this.snackBarCtrl.open(
				translate.instant('app.updating'),
				null,
				{
					duration: 2000
				}
			);
			snack.afterDismissed().subscribe(() => {
				window.location.reload();
			});
		});
		if (swUpdate.isEnabled) {
			console.log('Checking for App update');
			swUpdate
				.checkForUpdate()
				.finally(() => console.log('Update checking finished'));
		} else {
			swUpdate.activateUpdate().finally(async () => {
				console.log('Updates activated result: ' + swUpdate.isEnabled);
				if (swUpdate.isEnabled) {
					console.log('Checking for App update');
					await swUpdate.checkForUpdate();
				} else {
					console.log('SwUpdate is disabled (no service worker)');
				}
			});
		}
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					MaintenancePage.navigate(this.router);
				}
			})
			.catch(error => {
				console.error(error);
				this.snackBarCtrl.open(
					axiosErrorToMessage(this.translate, error),
					null,
					{
						duration: 2000
					}
				);
			});
	}

	ngOnInit() {}

	async handleCookieConsent() {
		console.log('handleCookieConsent()');
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.analytics.setAnalyticsCollectionEnabled(
			cookieConsentData.targeting
		);
	}

	private async initCookieConsent() {
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant(
					'cookie_consent.title.' + environment.customerGroup
				),
				cookies_policy_url: this.translate.instant(
					'cookie_consent.policy_url.' + environment.customerGroup
				)
			});
		};
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', onContentLoaded);
		} else {
			await onContentLoaded();
		}
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);
			await this.handleCookieConsent();
		};
		await this.handleCookieConsent();
	}
}
