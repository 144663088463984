import {Component, Input} from '@angular/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import {MyOrderActions} from '../../enums/MyOrderActions';

@Component({
	selector: 'app-order-modal',
	templateUrl: './order-modal.component.html',
	styleUrls: ['./order-modal.component.scss']
})
export class OrderModalComponent {
	@Input() orders: Order[] = [];

	constructor() {}

	onAction(event: MyOrderActions) {}
}
