import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {VenuePickerModalComponent} from '../../components/venue-picker-modal/venue-picker-modal.component';
import {Router} from '@angular/router';
import {MenuPage} from '../menu/menu.page.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppComponent} from '../../app.component';
import {CategoryPage} from '../category/category.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-home',
	templateUrl: 'home.page.html',
	styleUrls: ['home.page.scss']
})
export class HomePage implements OnInit {
	static url = 'home';

	loading = false;

	constructor(
		public modalCtrl: ModalController,
		private repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar
	) {}

	static navigate(router: Router, repository?: RepositoryService) {
		console.log(repository);
		if (repository) {
			repository.resetVenue();
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		router.navigateByUrl(HomePage.url);
	}

	ngOnInit() {}

	ionViewDidEnter() {}

	showVenuePicker() {
		this.loading = true;
		this.modalCtrl
			.create({
				cssClass: 'auto-size venue-modal',
				component: VenuePickerModalComponent,
				showBackdrop: true,
				backdropDismiss: true
			})
			.then(modal => {
				modal.onDidDismiss().then(async detail => {
					if (detail != null && detail.data != null) {
						try {
							console.log(detail.data._id);
							console.log(this.repository._venue?._id === detail.data._id);
							if (
								this.repository._venue &&
								this.repository._venue?._id === detail.data._id
							) {
								await sleep(100);
								MenuPage.navigate(this.router);
								if (AppComponent.largeScreen) {
									CategoryPage.navigate(this.router);
								} else {
									MenuPage.navigate(this.router);
								}
							} else {
								const venue = await this.repository.getVenue(detail.data._id);
								await sleep(100);
								venue.nutritionTable =
									'https://cdn.cinemaxx.de/-/media/images/cinemaxx/cinemaxxmenus/snackkompass_08102019.pdf';
								this.repository.venue.emit(venue);
								this.repository.createOrder(venue, null, PreorderType.INSIDE);

								MenuPage.navigate(this.router);
								if (AppComponent.largeScreen) {
									CategoryPage.navigate(this.router);
								} else {
									MenuPage.navigate(this.router);
								}
							}
						} catch (e) {
							this.snackbarCtrl.open(
								'Es gab ein Fehler beim laden des Kinos: ' + e,
								null,
								{
									duration: 2000
								}
							);
							console.error(e);
						}
						this.loading = false;
					} else {
						this.repository.venue = null;
						this.loading = false;
					}
				});
				modal.present();
			});
	}
}
