import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MySpacePage} from '../../my-space/my-space.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../../smoothr-web-app-core/services/repository/repository.service';
import {Gender} from '../../../../smoothr-web-app-core/enums/Gender';
import {Api} from '../../../../smoothr-web-app-core/api/api';

@Component({
	selector: 'app-base-data',
	templateUrl: './base-data.page.html',
	styleUrls: ['./base-data.page.scss']
})
export class BaseDataPage extends RepositoryDirective implements OnInit {
	static url = 'base-data';

	saving = false;
	gender = Gender;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar
	) {
		super(repository);
	}

	static navigate(router: Router) {
		router.navigateByUrl(BaseDataPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	async save() {
		if (!this.customer.isAnonymous) {
			this.saving = true;
			try {
				await Api.patchCustomer(this.customer);
				await this.repository.reloadCustomer();
				MySpacePage.navigate(this.router);
			} catch (e) {
				console.log(e);
				this.snackbarCtrl.open(e, null, {
					duration: 2000
				});
			}
			this.saving = false;
		}
	}
}
