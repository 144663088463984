import {apiEnvironment} from 'src/smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error'; // Included with Angular CLI.

export const cinemaxxCustomerGroup = 'cinemaxx';
export const customerGroup = cinemaxxCustomerGroup;

const supportEmail = {
	cinemaxx: 'cinemaxx@smoothr.de'
};
const firebaseConfig = {
	cinemaxx: {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:a052f607f8cba7e6e0fe06',
		measurementId: 'G-WM6W8JC3RB'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDh8cocZkqzzKrGdQAfGDyapN9-n84FDlM',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	...apiEnvironment,
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	countryList: ['de']
};
