import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-venue-picker-modal',
	templateUrl: './venue-picker-modal.component.html',
	styleUrls: ['./venue-picker-modal.component.scss']
})
export class VenuePickerModalComponent
	extends RepositoryDirective
	implements OnInit
{
	venues: Venue[] = [];
	loading = false;

	constructor(
		private modalCtrl: ModalController,
		public repository: RepositoryService,
		private alertCtrl: AlertController,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		Api.getAllVenues()
			.then(res => {
				this.venues.push(...res.data.filter(ven => ven.isServiceActivated));
				this.loading = false;
			})
			.catch(err => {
				console.log(err);
				this.snackBarCtrl.open(axiosErrorToMessage(this.translate, err), null, {
					duration: 2000
				});
				this.dismiss(null);
			});
	}

	async dismiss(selection: Venue) {
		console.log({
			method: 'dismiss()',
			selection: selection.name,
			venue: this.venue && this.venue.name
		});
		if (this.venue && this.venue._id !== selection._id) {
			this.alertCtrl
				.create({
					header: 'Bist du dir sicher?',
					message:
						'Wenn du einen anderen Kino wählst, wird dein Warenkorb geleert.',
					buttons: [
						{
							text: 'Abbrechen',
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {}
						},
						{
							text: 'Okay',
							handler: () => {
								this.repository.resetVenue();
								this.repository.order.emit(null);
								this.repository.verifiedOrder.emit(null);
								this.dismiss(selection);
							}
						}
					]
				})
				.then(alert => alert.present());
		} else {
			await this.modalCtrl.dismiss(selection);
		}
	}
}
