import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-push-modal',
	templateUrl: './push-modal.component.html',
	styleUrls: ['./push-modal.component.scss']
})
export class PushModalComponent implements OnInit {
	text: string;
	open = false;

	constructor() {}

	static async show(modalCtrl: ModalController, text: string) {
		const modal = await modalCtrl.create({
			component: PushModalComponent,
			showBackdrop: true,
			cssClass: 'auto-size',
			backdropDismiss: true,
			componentProps: {
				text
			}
		});
		await modal.present();
		let open = true;
		modal.onDidDismiss().then(() => (open = false));
		await Promise.race([modal.onDidDismiss(), sleep(1000)]);
		if (open) {
			await modal.dismiss();
		}
	}

	ngOnInit() {}
}
