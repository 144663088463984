import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArticleDetailsModalComponent} from '../article-details-modal/article-details-modal.component';
import {ModalController} from '@ionic/angular';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import Order from '../../../smoothr-web-app-core/models/Order';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import Article from '../../../smoothr-web-app-core/models/Article';
import {
	defaultsToArticleOptionForOrder,
	getPriceWithDefaults,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-list',
	templateUrl: './article-list.component.html',
	styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
	@Input() category: ArticleCategory;
	@Input() venue: Venue;
	@Input() order: Order;
	@Output() articleGroup = new EventEmitter<ArticleGroup>();
	numberToCurrency = numberToCurrency;
	getPriceWithDefaults = getPriceWithDefaults;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {}

	async openArticle(selectedArticle: Article) {
		if (selectedArticle.groups.length > 0) {
			const articleGroup = await ArticleDetailsModalComponent.show(
				this.modalCtrl,
				this.venue,
				selectedArticle,
				this.order.currency
			);
			if (articleGroup) {
				this.articleGroup.emit(articleGroup);
			}
		} else {
			const articleGroup = new ArticleGroup();
			articleGroup.article = selectedArticle;
			articleGroup.quantity = 1;
			articleGroup.groups = defaultsToArticleOptionForOrder(
				selectedArticle,
				selectedArticle.defaults,
				this.order
			);
			this.articleGroup.emit(articleGroup);
		}
	}
}
