import {Component, OnInit} from '@angular/core';
import {SignInPage} from '../sign-in/sign-in.page';
import {Router} from '@angular/router';
import {MyOrdersPage} from '../my-orders/my-orders.page';
import {BaseDataPage} from '../sign-up/base-data/base-data.page';
import {ScanQrPage} from '../scan-qr/scan-qr.page';
import {AlertController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {HomePage} from '../home/home.page';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-my-space',
	templateUrl: './my-space.page.html',
	styleUrls: ['./my-space.page.scss']
})
export class MySpacePage extends RepositoryDirective implements OnInit {
	static url = 'my-space';

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	static navigate(router: Router) {
		router.navigateByUrl(MySpacePage.url);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	myData() {
		BaseDataPage.navigate(this.router);
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_success'),
				null,
				{
					duration: 2000
				}
			);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_error'),
				null,
				{
					duration: 2000
				}
			);
		}
		await HomePage.navigate(this.router);
	}

	myOrders() {
		MyOrdersPage.navigate(this.router);
	}

	changePassword() {
		this.alertCtrl
			.create({
				header: 'Passwort ändern',
				message: 'Bitte neues Passwort 2x eingeben',
				inputs: [
					{
						name: 'password1',
						placeholder: 'Passwort eingeben',
						type: 'password'
					},
					{
						name: 'password2',
						placeholder: 'Passwort wiederholen',
						type: 'password'
					}
				],
				buttons: [
					{
						text: 'Abbrechen',
						role: 'cancel',
						cssClass: 'secondary'
					},
					{
						text: 'Ok',
						handler: result => {
							if (result.password1 === result.password2) {
								// todo change password
								// this.auth.currentUser.then(user => {
								// 	user.updatePassword(result.password1)
								// 		.then((_) => {
								// 			this.showSnackbar('Passwort erfolgreich geändert');
								// 		})
								// 		.catch((_) => {
								// 			this.showSnackbar('Fehler beim Ändern des Passwortes');
								// 		});
								// });
							} else {
								this.showSnackbar('Bitte überprüfen Sie die Eingabe');
								this.changePassword();
							}
						}
					}
				]
			})
			.then(alert => alert.present());
	}

	qrCodePayment() {
		ScanQrPage.navigate(this.router);
	}

	showSnackbar(message: string) {
		this.snackbarCtrl.open(message, null, {
			duration: 2000
		});
	}
}
