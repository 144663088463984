import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisplayIdentifier} from '../../enums/DisplayIdentifier';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';

@Component({
	selector: 'app-article-option-group-flavor',
	templateUrl: './article-option-group-flavor.component.html',
	styleUrls: ['./article-option-group-flavor.component.scss']
})
export class ArticleOptionGroupFlavorComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() showName: boolean;
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;

	options: ArticleOption[] = [];
	di = DisplayIdentifier;

	constructor() {}

	ngOnInit() {
		for (const article of this.optionGroup.articles) {
			const articleOption = new ArticleOption();
			articleOption.article = article;
			articleOption.group = this.optionGroup._id;
			articleOption.quantity = 1;
			this.options.push(articleOption);
		}
	}

	count(option: ArticleOption) {
		return this.selected
			.filter(
				selected =>
					selected.group === option.group &&
					selected.article._id === option.article._id
			)
			.map(value => value.quantity)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
	}
}
