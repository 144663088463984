import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MyOrderActions} from '../../enums/MyOrderActions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AlertController, ModalController} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import moment, {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import {OrderStatus} from 'src/smoothr-web-app-core/enums/OrderStatus';
import {
	Api,
	PAYMENT_SERVICE,
	WALLET_SERVICE
} from '../../../smoothr-web-app-core/api/api';
import {SlotsModalComponent} from '../slots-modal/slots-modal.component';
import {
	axiosErrorToMessage,
	orderStatusString
} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-my-order',
	templateUrl: './my-order.component.html',
	styleUrls: ['./my-order.component.scss']
})
export class MyOrderComponent {
	@Input() order: Order;
	@Output() action = new EventEmitter<MyOrderActions>();

	showQr = false;
	moment = moment;
	window: any = window;

	os = OrderStatus;

	orderStatusString = orderStatusString;
	loading = false;
	environment = environment;
	walletLink = MyOrderComponent.walletLink;
	invoiceLink = MyOrderComponent.invoiceLink;

	constructor(
		public snackBarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		public translate: TranslateService
	) {}

	static walletLink(orderId: string): string {
		return environment.baseUrl + WALLET_SERVICE + '/' + orderId + '/apple';
	}

	static invoiceLink(paymentId: string): string {
		return (
			environment.baseUrl +
			PAYMENT_SERVICE +
			'invoice/' +
			paymentId +
			'/customer/cinemaxx'
		);
	}

	toggleQr() {
		this.showQr = !this.showQr;
	}

	async alreadyHere() {
		this.loading = true;
		if (!(await this.getUserConfirmation(false))) {
			this.loading = false;
			return;
		}
		try {
			const orderRes = await Api.preponeSlot(this.order._id);
			this.order = orderRes.data;
			this.action.emit(MyOrderActions.onHereNow);
		} catch (e) {
			this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async laterHere() {
		this.loading = true;
		try {
			const venue = (await Api.getLazyVenue(this.order.venue)).data;
			const slot = await SlotsModalComponent.show(
				this.modalCtrl,
				venue,
				this.order
			);
			if (slot != null) {
				await this.changeSlot(slot);
			} else {
			}
		} catch (e) {
			this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async changeSlot(time: Moment) {
		this.loading = true;
		if (!(await this.getUserConfirmation(false))) {
			this.loading = false;
			return;
		}
		try {
			this.order = (await Api.changeSlot(this.order, time)).data;
			this.action.emit(MyOrderActions.onLaterHere);
		} catch (e) {
			this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async cancel() {
		this.loading = true;
		if (!(await this.getUserConfirmation(true))) {
			this.loading = false;
			return;
		}
		try {
			await Api.cancelOrder(this.order._id);
			this.order.status = OrderStatus.CANCELLED_BY_CUSTOMER;
			this.snackBarCtrl.open('Bestellung erfolgreich abgerbochen!', null, {
				duration: 2000
			});
			this.action.emit(MyOrderActions.onCancel);
		} catch (e) {
			this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async getUserConfirmation(isCancel = false): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.alertCtrl
				.create({
					header: isCancel ? 'Bestellung stornieren?' : 'Abholzeit anpassen?',
					message: isCancel
						? 'Möchtest du wirklich deine Bestellung stornieren?'
						: 'Möchtest du wirklich deine Abholzeit ändern?',
					buttons: [
						{
							text: 'Abbrechen',
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {
								resolve(false);
							}
						},
						{
							text: 'Ok',
							handler: () => {
								resolve(true);
							}
						}
					]
				})
				.then(alert => alert.present());
		});
	}
}
