import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';

@Component({
	selector: 'app-article-option-group-drink-flavor',
	templateUrl: './article-option-group-drink-flavor.component.html',
	styleUrls: ['./article-option-group-drink-flavor.component.scss']
})
export class ArticleOptionGroupDrinkFlavorComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	freeOptions: ArticleOption[] = [];
	premiumOptions: ArticleOption[] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	private _selected: ArticleOption[] = [];

	get selected(): ArticleOption[] {
		return this._selected;
	}

	@Input()
	set selected(value: ArticleOption[]) {
		this._selected = value;
		this.cdr.detectChanges();
	}

	ngOnInit() {
		for (const article of this.optionGroup.articles) {
			if (!article.visible) {
				continue;
			}
			const articleOption = new ArticleOption();
			articleOption.article = article;
			articleOption.group = this.optionGroup._id;
			articleOption.quantity = 1;
			if (article.price.$numberDecimal <= 0) {
				this.freeOptions.push(articleOption);
			} else {
				this.premiumOptions.push(articleOption);
			}
		}
	}

	selectedCount(option: ArticleOption): number {
		const articleOption = this.selected.find(selected => {
			return (
				selected.group === option.group &&
				selected.article._id === option.article._id // &&
				// selected.dependencyNumber === option.dependencyNumber &&
				// selected.dependency === option.dependency &&
				// selected.dependsOn === option.dependsOn
			);
		});
		if (articleOption == null) {
			return 0;
		} else {
			return articleOption.quantity;
		}
	}
}
