import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NavigationGuardService} from './services/navigation-guard/navigation-guard.service';
import {CategoryPage} from './pages/category/category.page';
import {HomePage} from './pages/home/home.page';
import {MenuExtraPage} from './pages/menu-extra/menu-extra.page';
import {OrderPage} from './pages/order/order.page';
import {SignUpPage} from './pages/sign-up/sign-up.page';
import {SignInPage} from './pages/sign-in/sign-in.page';
import {MySpacePage} from './pages/my-space/my-space.page';
import {MyOrdersPage} from './pages/my-orders/my-orders.page';
import {PaymentSuccessPage} from './pages/payment-success/payment-success.page';
import {MenuPage} from './pages/menu/menu.page.component';
import {ScanQrPage} from './pages/scan-qr/scan-qr.page';
import {EmailActionPage} from './pages/email-action/email-action.page';
import {EmailConfirmationPage} from './pages/email-confirmation/email-confirmation.page';
import {CheckOrderGuard} from './services/navigation-guard/check-order.service';
import {ImpressumPage} from './pages/impressum/impressum.page';
import {TosPage} from './pages/tos/tos.page';
import {PrivacyPage} from './pages/privacy/privacy.page';

const routes: Routes = [
	{
		path: '',
		redirectTo: HomePage.url,
		pathMatch: 'full',
		canDeactivate: [NavigationGuardService]
	},
	{
		path: OrderPage.cancelUrlWithPaymentParam,
		redirectTo: OrderPage.paymentCancelUrl
	},
	{
		path: OrderPage.failUrlWithPaymentParam,
		redirectTo: OrderPage.paymentFailUrl
	},
	{
		path: HomePage.url,
		loadChildren: () =>
			import('./pages/home/home.module').then(m => m.HomePageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: MenuPage.url,
		loadChildren: () =>
			import('./pages/menu/menu.module').then(m => m.MenuPageModule),
		canActivate: [CheckOrderGuard],
		canDeactivate: [NavigationGuardService]
	},
	{
		path: CategoryPage.url,
		loadChildren: () =>
			import('./pages/category/category.module').then(
				m => m.CategoryPageModule
			),
		canActivate: [CheckOrderGuard],
		canDeactivate: [NavigationGuardService]
	},
	{
		path: PaymentSuccessPage.urlWithPaymentParam,
		loadChildren: () =>
			import('./pages/payment-success/payment-success.module').then(
				m => m.PaymentSuccessPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: PaymentSuccessPage.url,
		loadChildren: () =>
			import('./pages/payment-success/payment-success.module').then(
				m => m.PaymentSuccessPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: OrderPage.paymentFailUrl,
		loadChildren: () =>
			import('./pages/order/order.module').then(m => m.OrderPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: OrderPage.paymentCancelUrl,
		loadChildren: () =>
			import('./pages/order/order.module').then(m => m.OrderPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: OrderPage.url,
		loadChildren: () =>
			import('./pages/order/order.module').then(m => m.OrderPageModule),
		canActivate: [CheckOrderGuard],
		canDeactivate: [NavigationGuardService]
	},
	{
		path: ImpressumPage.url,
		loadChildren: () =>
			import('./pages/impressum/impressum.module').then(
				m => m.ImpressumPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: TosPage.url,
		loadChildren: () =>
			import('./pages/tos/tos.module').then(m => m.TosPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: PrivacyPage.url,
		loadChildren: () =>
			import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: MenuExtraPage.url,
		loadChildren: () =>
			import('./pages/menu-extra/menu-extra.module').then(
				m => m.MenuExtraPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: SignUpPage.url,
		loadChildren: () =>
			import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: SignInPage.url,
		loadChildren: () =>
			import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: 'base-data',
		loadChildren: () =>
			import('./pages/sign-up/base-data/base-data.module').then(
				m => m.BaseDataPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: MySpacePage.url,
		loadChildren: () =>
			import('./pages/my-space/my-space.module').then(m => m.MySpacePageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: MyOrdersPage.url,
		loadChildren: () =>
			import('./pages/my-orders/my-orders.module').then(
				m => m.MyOrdersPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: ScanQrPage.url + '/:order',
		loadChildren: () =>
			import('./pages/scan-qr/scan-qr.module').then(m => m.ScanQrPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: ScanQrPage.url,
		loadChildren: () =>
			import('./pages/scan-qr/scan-qr.module').then(m => m.ScanQrPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: 'order-transferred',
		loadChildren: () =>
			import('./pages/order-transferred/order-transferred.module').then(
				m => m.OrderTransferredPageModule
			),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: ScanQrPage.url + '/:order',
		loadChildren: () =>
			import('./pages/scan-qr/scan-qr.module').then(m => m.ScanQrPageModule),
		canDeactivate: [NavigationGuardService]
	},
	{
		path: EmailActionPage.url,
		loadChildren: () =>
			import('./pages/email-action/email-action.module').then(
				m => m.EmailActionPageModule
			)
	},
	{
		path: EmailConfirmationPage.url,
		loadChildren: () =>
			import('./pages/email-confirmation/email-confirmation.module').then(
				m => m.EmailConfirmationPageModule
			),
		canDeactivate: [NavigationGuardService]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
