import {ChangeDetectorRef, Component} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {OrderStatus} from 'src/smoothr-web-app-core/enums/OrderStatus';
import Order from 'src/smoothr-web-app-core/models/Order';
import {MyOrderActions} from '../../enums/MyOrderActions';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.page.html',
	styleUrls: ['./my-orders.page.scss']
})
export class MyOrdersPage extends RepositoryDirective {
	static url = 'my-orders';
	moment = moment;

	orders: Order[] = [];
	loading = false;
	displayedQrs: string[] = [];

	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	get openOrders(): Order[] {
		return this.orders.filter(value => {
			return (
				value.status !== OrderStatus.DONE &&
				value.status !== OrderStatus.CANCELLED_BY_CUSTOMER
			);
		});
	}

	get doneOrders(): Order[] {
		return this.orders.filter(value => {
			return (
				value.status !== OrderStatus.AWAITING_CONFIRMATION &&
				value.status !== OrderStatus.READY &&
				value.status !== OrderStatus.IN_PREPARATION
			);
		});
	}

	static navigate(router: Router) {
		router.navigateByUrl(MyOrdersPage.url);
	}

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	async loadOrders() {
		this.loading = true;
		this.orders = [];
		if (this.customer == null) {
			console.log('Customer not loaded');
			this.loading = false;
			return;
		}
		try {
			const res = await Api.getCustomerOrders(this.customer.uid);
			this.orders = res.data.withPayment;
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
				duration: 2000
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAction(event: MyOrderActions) {
		this.loadOrders();
	}
}
