import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../../pages/home/home.page';
import {MenuPage} from '../../pages/menu/menu.page.component';

@Component({
	selector: 'app-toolbar-step',
	templateUrl: './toolbar-step.component.html',
	styleUrls: ['./toolbar-step.component.scss']
})
export class ToolbarStepComponent implements OnInit {
	@Input() index: number;
	@Input() enabled: boolean = true;

	constructor(private router: Router) {}

	ngOnInit() {}

	menu() {
		if (this.router.url !== '/' + HomePage.url) {
			MenuPage.navigate(this.router);
		}
	}
}
