import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-delete-cart-item-modal',
	templateUrl: './delete-cart-item-modal.component.html',
	styleUrls: ['./delete-cart-item-modal.component.scss']
})
export class DeleteCartItemModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(
		modalCtrl: ModalController
	): Promise<DeleteCartItemModalComponentResult> {
		const modal = await modalCtrl.create({
			component: DeleteCartItemModalComponent,
			showBackdrop: true,
			cssClass: 'auto-size',
			backdropDismiss: true
		});
		await modal.present();
		return (await modal.onDidDismiss()).data;
	}

	ngOnInit() {}

	positive() {
		this.modalCtrl.dismiss(DeleteCartItemModalComponentResult.POSITIVE);
	}

	negative() {
		this.modalCtrl.dismiss(DeleteCartItemModalComponentResult.NEGATIVE);
	}
}

export enum DeleteCartItemModalComponentResult {
	POSITIVE,
	NEGATIVE
}
