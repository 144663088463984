import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {Subscription} from 'rxjs';
import Customer from '../../../smoothr-web-app-core/models/Customer';
import {environment} from 'src/environments/environment';
import {BaseDataPage} from '../sign-up/base-data/base-data.page';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-email-confirmation',
	templateUrl: './email-confirmation.page.html',
	styleUrls: ['./email-confirmation.page.scss']
})
export class EmailConfirmationPage implements OnInit, OnDestroy {
	static url = 'confirm-email';

	customer: Customer;
	loading = false;
	environment = environment;
	private readonly subscription: Subscription;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService
	) {}

	static async navigate(router: Router, replaceUrl: boolean = false) {
		await router.navigateByUrl(EmailConfirmationPage.url, {
			replaceUrl
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([EmailConfirmationPage.url], {
			queryParams,
			replaceUrl: true
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (!params.token || !params.mode || params.mode !== 'verifyEmail') {
				return;
			}
			try {
				this.repository.customerAuth.emit(
					(await Api.verifyEmail(params.token)).data
				);
			} catch (e) {
				this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
					duration: 2000
				});
				console.log('Error while verifying email');
			}
			await this.refresh();
		});
	}

	async refresh() {
		this.loading = true;
		try {
			this.customer = await this.repository.reloadCustomer();
		} catch (e) {}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async close() {
		if (this.loading) {
			return;
		}
		HomePage.navigate(this.router);
	}

	async proceed() {
		await BaseDataPage.navigate(this.router);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}
}
