import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';

@Component({
	selector: 'app-to-order-button',
	templateUrl: './to-order-button.component.html',
	styleUrls: ['./to-order-button.component.scss']
})
export class ToOrderButtonComponent
	extends RepositoryDirective
	implements OnInit
{
	price: number;
	active: boolean;
	priceText: string;

	constructor(
		public repository: RepositoryService,
		private router: Router
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	onOrder() {
		super.onOrder();
		this.price = this.order
			? OrderUtils.orderTotalPrice(this.order, true, true)
			: 0;
		this.active = this.price > 0;
		this.priceText =
			this.order && this.order.currency
				? numberToCurrency(this.price, this.order.currency)
				: this.price.toString();
	}

	goToOrder() {
		if (this.active) {
			this.router.navigateByUrl('order');
		}
	}
}
