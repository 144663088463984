import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderListAction} from '../../enums/OrderListAction';
import {DisplayIdentifier} from '../../enums/DisplayIdentifier';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import Order from '../../../smoothr-web-app-core/models/Order';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import Article from 'src/smoothr-web-app-core/models/Article';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
	@Input() articleGroup: ArticleGroup;
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	@Input() order: Order;
	@Input() currencyCode: string;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	orderUtils = OrderUtils;

	constructor() {}

	get articlePrice(): number {
		if (!this.articleGroup) {
			return 0;
		}
		const base = this.articleGroup.article
			? +getPrice(this.articleGroup.article, this.order.type, null, null)
			: 0;
		let addition = 0;
		for (const option of this.articleGroup.groups) {
			const grp = this.articleGroup.article.groups.find(
				grp => grp._id === option.group
			);
			if (
				grp &&
				grp.displayIdentifiers &&
				grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice)
			) {
				addition += +getPrice(option.article, this.order.type, null, null);
			}
		}
		return base + addition;
	}

	ngOnInit() {}

	edit() {
		this.action.emit(OrderListAction.edit);
	}

	delete() {
		this.action.emit(OrderListAction.delete);
	}

	add() {
		this.action.emit(OrderListAction.add);
	}

	remove() {
		this.action.emit(OrderListAction.remove);
	}
	tagEmpty(article: Article) {
		if (article?.tags?.length == 0) {
			return true;
		}
		return !article?.tags?.find(tag => tag?.identifier === 'empty');
	}
}
