import {Component, OnInit} from '@angular/core';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {Router} from '@angular/router';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {
	AlertController,
	ModalController,
	PopoverController
} from '@ionic/angular';
import {ScanQrPage} from '../../pages/scan-qr/scan-qr.page';
import moment from 'moment';
import {OrderModalComponent} from '../order-modal/order-modal.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HomePage} from '../../pages/home/home.page';
import {MySpacePage} from '../../pages/my-space/my-space.page';
import {PrivacyPage} from 'src/app/pages/privacy/privacy.page';
import {TosPage} from 'src/app/pages/tos/tos.page';
import {ImpressumPage} from 'src/app/pages/impressum/impressum.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-menu-popover',
	templateUrl: './menu-popover.component.html',
	styleUrls: ['./menu-popover.component.scss']
})
export class MenuPopoverComponent
	extends RepositoryDirective
	implements OnInit
{
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private popoverCtrl: PopoverController,
		private alertCtrl: AlertController,
		private modalCtrl: ModalController,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	async signIn() {
		await this.popoverCtrl.dismiss();
		SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.popoverCtrl.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.popoverCtrl.dismiss();
		MySpacePage.navigate(this.router);
	}

	async scanQr() {
		await this.popoverCtrl.dismiss();
		ScanQrPage.navigate(this.router);
	}

	async retrieveOrder() {
		await this.popoverCtrl.dismiss();
		const venue = await this.showVenueSelectionDialog();
		await this.showOrderRetrieveDialog(venue);
	}

	async showVenueSelectionDialog(): Promise<Venue> {
		return new Promise<Venue>(async resolve => {
			const alert = await this.alertCtrl.create({
				header: 'Wähle dein Kino aus',
				message:
					'Um deine Bestellung zu finden müssen wir wissen wo du bestellt hast.',
				inputs: (await Api.getAllVenues()).data.map(ven => {
					return {
						name: 'venue',
						value: ven,
						label: ven.name,
						type: 'radio'
					};
				}),
				buttons: [
					{
						text: 'Abbrechen',
						role: 'cancel',
						cssClass: 'secondary'
					},
					{
						text: 'Ok',
						handler: async result => {
							resolve(result);
						}
					}
				]
			});
			await alert.present();
		});
	}

	async showOrderRetrieveDialog(venue: Venue) {
		const alert = await this.alertCtrl.create({
			header: 'Bestellung anzeigen',
			message: 'Bitte Details der Bestellung eingeben',
			inputs: [
				{
					name: 'orderNumber',
					placeholder: 'Bestellnummer',
					type: 'text'
				},
				{
					name: 'date',
					placeholder: 'Datum',
					type: 'date',
					value: 'tt.mm.jjjj'
				},
				{
					name: 'time',
					placeholder: 'Uhrzeit',
					type: 'time',
					value: moment().format('HH:mm')
				}
			],
			buttons: [
				{
					text: 'Abbrechen',
					role: 'cancel',
					cssClass: 'secondary'
				},
				{
					text: 'Ok',
					handler: async result => {
						try {
							const orderRes = await Api.getOrderByCodeAndDate(
								venue._id,
								result.orderNumber.toUpperCase(),
								moment(result.date + ' ' + result.time)
									.utc()
									.toISOString()
							);
							await (
								await this.modalCtrl.create({
									cssClass: 'auto-size',
									component: OrderModalComponent,
									componentProps: {
										orders: [orderRes.data]
									}
								})
							).present();
						} catch (e) {
							console.error(e);
							this.snackBarCtrl.open(
								axiosErrorToMessage(this.translate, e),
								null,
								{
									duration: 2000
								}
							);
							this.snackBarCtrl.open(
								'Bestellung wurde nicht gefunden! Bitte überprüfen Sie ihre Eingabe',
								null,
								{
									duration: 2000
								}
							);
						}
					}
				}
			]
		});
		await alert.present();
	}

	async openNutritionTable() {
		await this.popoverCtrl.dismiss();
		window.open(this.venue.nutritionTable, '_system', 'location=yes');
	}

	async home() {
		await this.popoverCtrl.dismiss();
		HomePage.navigate(this.router);
	}

	async privacy() {
		await this.popoverCtrl.dismiss();
		PrivacyPage.navigate(this.router);
	}

	async tos() {
		await this.popoverCtrl.dismiss();
		TosPage.navigate(this.router);
	}

	async impressum() {
		await this.popoverCtrl.dismiss();
		ImpressumPage.navigate(this.router);
	}
}
