import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';
import {MyOrderComponent} from '../../components/my-order/my-order.component';
import {environment} from 'src/environments/environment';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {
	axiosErrorToMessage,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import Order from '../../../smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['./payment-success.page.scss']
})
export class PaymentSuccessPage implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	orderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	largeScreen: boolean;
	orderId: string;
	window: any = window;
	loading = false;
	environment = environment;
	walletLink = MyOrderComponent.walletLink;
	verifiedOrder: Order;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService
	) {}

	static async navigate(router: Router, order: string, payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order
			}
		});
	}

	ngOnInit() {
		this.largeScreen = AppComponent.largeScreen;
		this.route.queryParams.subscribe(params => {
			this.verifiedOrder = this.repository._verifiedOrder;
			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder(this.orderId);
		});
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
	}

	navigateMenu() {
		this.alertCtrl
			.create({
				header: 'Zum Start',
				message:
					'Wenn du zurück zur Kinoauswahl gehst, kannst du nur auf' +
					' deine Bestellung zugreifen in dem du in deinem E-Mail-Postfach oder unter deinen Bestellungen schaust.',
				buttons: [
					{
						text: 'Hier bleiben',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {}
					},
					{
						text: 'Okay',
						handler: () => {
							HomePage.navigate(this.router);
						}
					}
				]
			})
			.then(alert => alert.present());
	}

	async reloadOrder(order: string, attempt: number = 0, error = null) {
		this.error = null;
		if (attempt > 10) {
			this.error = error
				? axiosErrorToMessage(this.translate, error)
				: this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000
			});
			this.loading = false;
			return;
		}
		if (attempt > 0) {
			await sleep(200);
		}
		try {
			this.verifiedOrder = (await Api.getOrder(order)).data;
			this.repository.verifiedOrder.emit();
			if (!this.verifiedOrder.isPayed) {
				await this.reloadOrder(
					order,
					attempt + 1,
					this.translate.instant('payment_success_page.please_reload')
				);
				return;
			}
			this.analytics.paymentSuccess();
			if (
				this.repository._venue &&
				this.repository._venue._id === this.verifiedOrder.venue
			) {
				this.orderVenue = this.repository._venue;
			} else {
				this.orderVenue = await this.repository.getVenue(
					this.verifiedOrder.venue
				);
			}
		} catch (err) {
			console.error(err);
			await this.reloadOrder(order, attempt + 1, err);
		}
	}

	home() {
		if (this.error) {
			this.reloadOrder(this.orderId);
			return;
		}
		HomePage.navigate(this.router, this.repository);
	}
}
