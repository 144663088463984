import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {CategoryPage} from '../category/category.page';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Article from '../../../smoothr-web-app-core/models/Article';
import {isActiveForOrder} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.component.html',
	styleUrls: ['./menu.page.component.scss']
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, {static: true}) toOrderButton;

	categories: ArticleCategory[] = [];
	searchTerm = '';

	constructor(
		public repository: RepositoryService,
		private router: Router
	) {
		super(repository);
	}

	static navigate(router: Router) {
		if (AppComponent.largeScreen) {
			CategoryPage.navigate(router);
			return;
		}
		router.navigateByUrl(MenuPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	onVenue() {
		super.onVenue();
		this.reloadCategories();
	}

	onOrder() {
		super.onOrder();
		if (this.categories.length === 0) {
			this.reloadCategories();
		}
	}

	async reloadCategories() {
		if (!this.venue || !this.order) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm =
			this.searchTerm && this.searchTerm !== ''
				? this.searchTerm.toLocaleLowerCase()
				: null;
		const allArticles: Article[] = [];
		this.venue.articleCategories.forEach(cat =>
			allArticles.push(...cat.articles)
		);
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = isActiveForOrder(allArticles, article, this.order);
					if (available && lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase()
						];
						return (
							available &&
							keys
								.map(key => key.indexOf(lowerSearchTerm))
								.find(result => result >= 0) !== undefined
						);
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
	}

	open(category: ArticleCategory) {
		CategoryPage.navigate(this.router, category._id);
	}
}
