import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import {DisplayMode} from '../../../enums/DisplayMode';

@Component({
	selector: 'app-category-navigator',
	templateUrl: './category-navigator.component.html',
	styleUrls: ['./category-navigator.component.scss']
})
export class CategoryNavigatorComponent implements OnInit {
	@Input() categories: ArticleCategory[];
	@Input() index: number;
	@Output() next = new EventEmitter();
	@Output() previous = new EventEmitter();

	dm = DisplayMode;

	constructor() {}

	ngOnInit() {}

	prevVisible(): boolean {
		return (
			this.index > 0 &&
			this.categories[this.index - 1] != undefined &&
			this.categories[this.index - 1].visible
		);
	}

	nextVisible(): boolean {
		return (
			this.index + 1 < this.categories.length &&
			this.categories[this.index + 1].visible
		);
	}
}
