import {Component, OnDestroy} from '@angular/core';
import {SignInPage} from '../sign-in/sign-in.page';
import {Router} from '@angular/router';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmailConfirmationPage} from '../email-confirmation/email-confirmation.page';
import {TranslateService} from '@ngx-translate/core';
import {Gender} from 'src/smoothr-web-app-core/enums/Gender';
import {Subscription} from 'rxjs';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {MySpacePage} from '../my-space/my-space.page';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {MenuPage} from '../menu/menu.page.component';
import {HomePage} from '../home/home.page';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['./sign-up.page.scss']
})
export class SignUpPage implements OnDestroy {
	static url = 'sign-up';

	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;

	venues: Venue[] = [];
	fullName = '';
	loading = false;
	isValid = false;
	Gender = Gender;
	gender: Gender = null;
	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			MySpacePage.navigate(router);
		}
	}

	private _selectedVenue: Venue;

	get selectedVenue(): Venue {
		return this._selectedVenue;
	}

	set selectedVenue(value: Venue) {
		this._selectedVenue = value;
		this.validate();
	}

	private _email = '';

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
		this.validate();
	}

	private _password = '';

	get password(): string {
		return this._password;
	}

	set password(value: string) {
		this._password = value;
		this.validate();
	}

	private _ageChecked = false;

	get ageChecked(): boolean {
		return this._ageChecked;
	}

	set ageChecked(value: boolean) {
		this._ageChecked = value;
		this.validate();
	}

	private _agbChecked = false;

	get agbChecked(): boolean {
		return this._agbChecked;
	}

	set agbChecked(value: boolean) {
		this._agbChecked = value;
		this.validate();
	}

	private _privacyChecked = false;

	get privacyChecked(): boolean {
		return this._privacyChecked;
	}

	set privacyChecked(value: boolean) {
		this._privacyChecked = value;
		this.validate();
	}

	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}

	ngOnInit() {
		Api.getAllVenues().then(vens => {
			this.venues.push(...vens.data);
		});
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	validate(): string {
		if (this.gender === null) {
			this.isValid = false;
			return 'sign_up.validation.gender';
		}

		if (this.fullName.split(' ').length < 2) {
			this.isValid = false;
			this.isValidName = false;
			return 'sign_up.validation.name';
		} else {
			this.isValidName = true;
		}

		if (!validator.isEmail(this.email)) {
			this.isValid = false;
			this.isValidEmail = false;
			return 'sign_up.validation.email';
		} else {
			this.isValidEmail = true;
		}

		if (!ValidationUtils.validatePassword(this.password)) {
			this.isValid = false;
			this.isValidPassword = false;
			return 'sign_up.validation.password';
		} else {
			this.isValidPassword = true;
		}
		if (this.repository.customer === null) {
			this.isValid = false;
			return 'sign_up.validation.user';
		}
		if (!this.repository.customer.isAnonymous) {
			this.isValid = false;
			return 'sign_up.validation.already_signed_in';
		}
		if (!this.agbChecked) {
			this.isValid = false;
			return 'sign_up.validation.agb';
		}
		if (!this.ageChecked) {
			this.isValid = false;
			return 'sign_up.validation.age';
		}
		if (!this.privacyChecked) {
			this.isValid = false;
			return 'sign_up.validation.privacy';
		}
		this.isValid = true;
		this.isValidName = true;
		this.isValidPassword = true;
		this.isValidEmail = true;
		return null;
	}

	async signUp() {
		const result = this.validate();
		if (result) {
			this.snackbarCtrl.open(this.translate.instant(result), null, {
				duration: 5000
			});
			return;
		}
		if (!this.loading) {
			this.loading = true;
			try {
				await Api.signUpWithCredentials(
					this.fullName,
					this.gender,
					this.email,
					this.password,
					[this.selectedVenue._id]
				);
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
				}
				this.snackbarCtrl.open(axiosErrorToMessage(this.translate, e));
			}
			this.loading = false;
		}
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
	selectGender(value: any) {
		this.selectedVenue = value.detail.value;
	}
}
