import {Component, Input, OnInit} from '@angular/core';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import Article from '../../../smoothr-web-app-core/models/Article';
import {
	genArray,
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.html',
	styleUrls: ['./item-selectable-article.component.scss']
})
export class ItemSelectableArticleComponent implements OnInit {
	@Input() selected: number;
	@Input() showName: boolean;
	@Input() unselectTransparency = false;
	@Input() priceOnImage = false;
	@Input() nameBoldGrey = false;
	@Input() smallName = false;
	@Input() orderType: OrderType;
	@Input() article: Article;
	@Input() currency: string;
	price: number = 0;
	basePrice: number = 0;
	PreorderType = PreorderType;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	genArray = genArray;

	constructor() {}

	private _option: ArticleOption;

	get option(): ArticleOption {
		return this._option;
	}

	@Input() set option(value: ArticleOption) {
		this._option = value;
		if (this._option && this._option.article) {
			this.price = getPrice(
				this._option.article,
				this.orderType,
				PreorderType.INSIDE,
				null
			);
		}
	}

	ngOnInit() {}
}
