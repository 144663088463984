import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {MenuPage} from '../menu/menu.page.component';
import {CookieService} from 'ngx-cookie-service';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage {
	static url = 'privacy';

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private cookieService: CookieService
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
	resetCookies() {
		this.cookieService.delete('cookie_consent_level');
		this.cookieService.delete('cookie_consent_user_accepted');
		window.location.reload();
	}
}
