import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {OrderModalComponent} from '../../components/order-modal/order-modal.component';
import {PaymentSuccessPage} from '../payment-success/payment-success.page';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {
	axiosErrorToMessage,
	paymentFromOrder
} from '../../../smoothr-web-app-core/utils/utils';
import {environment} from '../../../environments/environment';
import {
	PaymentModalComponent,
	PaymentModalResult
} from '../../../smoothr-web-app-core/components/payment-modal/payment-modal.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-scan-qr',
	templateUrl: './scan-qr.page.html',
	styleUrls: ['./scan-qr.page.scss']
})
export class ScanQrPage extends RepositoryDirective implements OnInit {
	static url = 'scan-qr';
	scanning = false;
	loading = false;

	constructor(
		private snackBarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private router: Router,
		private modalCtrl: ModalController,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService
	) {
		super(repository);
	}

	static navigate(router: Router) {
		router.navigateByUrl(ScanQrPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		if (this.repository.customer == null) {
			setTimeout(() => this.ngOnInit(), 500);
			return;
		}
		const orderId = this.activatedRoute.snapshot.paramMap.get('order');
		if (orderId !== null) {
			this.snackBarCtrl.open(
				'Wir suchen deine Bestellung. Einen Moment...',
				null,
				{
					duration: 2000
				}
			);
			this.takeOverOrder(orderId);
		} else {
			this.loading = false;
		}
	}

	startScanning() {
		this.scanning = true;
	}

	stopScanning() {
		this.scanning = false;
	}

	scanSuccessHandler($event: string) {
		this.loading = true;
		this.snackBarCtrl.open(
			'Wir suchen deine Bestellung. Einen Moment...',
			null,
			{
				duration: 2000
			}
		);
		this.stopScanning();
		try {
			this.takeOverOrder($event.split(environment.webAppUrl + '/scan-qr/')[1]);
		} catch (e) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
		}
	}

	async takeOverOrder(orderId: string) {
		if (orderId == null) {
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000
			});
		} else {
			try {
				const order = (await Api.getOrder(orderId)).data;
				const venue = await this.repository.getVenue(order.venue);
				this.repository.order.emit(order);
				this.repository.verifiedOrder.emit(order);
				this.repository.venue.emit(venue);
				if (order.isPayed) {
					this.modalCtrl
						.create({
							cssClass: 'auto-size',
							component: OrderModalComponent,
							componentProps: {
								orders: [order]
							}
						})
						.then(modal => modal.present());
				} else {
					const result = await PaymentModalComponent.show(
						this.modalCtrl,
						venue,
						paymentFromOrder(order),
						order,
						this.customer
					);
					if (result && result.result === PaymentModalResult.SUCCESS) {
						await PaymentSuccessPage.navigate(
							this.router,
							order._id,
							result.payment._id
						);
						return;
					}
				}
			} catch (e) {
				console.log(e);
				this.snackBarCtrl.dismiss();
				this.snackBarCtrl.open(
					'Fehler beim Abrufen der Bestellung: ' +
						axiosErrorToMessage(this.translate, e),
					null,
					{
						duration: 2000
					}
				);
			}
			this.loading = false;
		}
	}
}
