import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {NavigationGuardService} from './services/navigation-guard/navigation-guard.service';
import {VenuePickerModalComponent} from './components/venue-picker-modal/venue-picker-modal.component';
import {SharedModuleModule} from './shared-module/shared-module.module';
import {ArticleDetailsModalComponent} from './components/article-details-modal/article-details-modal.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MenuPopoverComponent} from './components/menu-popover/menu-popover.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ZXingScannerModule} from '../scanner/lib/zxing-scanner.module';
import {LoginOrderModalComponent} from './components/login-order-modal/login-order-modal.component';
import {OrderModalComponent} from './components/order-modal/order-modal.component';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {PushModalComponent} from './components/push-modal/push-modal.component';
import {DeleteCartItemModalComponent} from './components/delete-cart-item-modal/delete-cart-item-modal.component';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {PaymentModalComponent} from '../smoothr-web-app-core/components/payment-modal/payment-modal.component';
import {CheckoutModalComponent} from './components/checkout-modal/checkout-modal.component';
import {CheckOrderGuard} from './services/navigation-guard/check-order.service';
import {AnalyticsService} from '../smoothr-web-app-core/services/analytics/analytics.service';
import {RepositoryService} from '../smoothr-web-app-core/services/repository/repository.service';
// import {CalendarModule} from 'ion2-calendar';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector
) {
	return () =>
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(
				LOCATION_INITIALIZED,
				Promise.resolve(null)
			);
			locationInitialized.then(() => {
				translate.use(translate.defaultLang).subscribe(
					() => {},
					() => {},
					() => {
						resolve(null);
					}
				);
			});
		});
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			spinner: 'crescent',
			swipeBackEnabled: false,
			scrollAssist: false,
			mode: 'md'
		}),
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAnalyticsModule,
		SharedModuleModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
		HttpClientModule,
		// CalendarModule,
		NoopAnimationsModule,
		ZXingScannerModule,
		TranslateModule.forRoot({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		AnalyticsService,
		RepositoryService,
		NavigationGuardService,
		CheckOrderGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{provide: LOCALE_ID, useValue: 'de-DE'},
		{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
	],
	entryComponents: [
		VenuePickerModalComponent,
		ArticleDetailsModalComponent,
		MenuPopoverComponent,
		LoginOrderModalComponent,
		OrderModalComponent,
		PushModalComponent,
		DeleteCartItemModalComponent,
		PaymentModalComponent,
		CheckoutModalComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
