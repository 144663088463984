import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Article from '../../../smoothr-web-app-core/models/Article';
import {getPrice, numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-toggle-button-group',
	templateUrl: './toggle-button-group.component.html',
	styleUrls: ['./toggle-button-group.component.scss']
})
export class ToggleButtonGroupComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() currency: string;
	@Output() selection = new EventEmitter<ArticleOption>();
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	ot = OrderType;
	pt = PreorderType;

	constructor(public repository: RepositoryService) {}

	ngOnInit() {}

	isSelected(article: Article) {
		return (
			this.selected.findIndex(
				selected =>
					selected.group === this.optionGroup._id &&
					selected.article._id === article._id
			) >= 0
		);
	}

	select(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.group = this.optionGroup._id;
		option.quantity = 1;
		this.selection.emit(option);
	}
}
