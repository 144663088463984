import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import moment, {Moment} from 'moment';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Slot from 'src/smoothr-web-app-core/models/Slot';
import {
	getSlotsByOrder,
	isSlotFullByOrder
} from 'src/smoothr-web-app-core/utils/utils';
import Order from 'src/smoothr-web-app-core/models/Order';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-slots-modal',
	templateUrl: './slots-modal.component.html',
	styleUrls: ['./slots-modal.component.scss']
})
export class SlotsModalComponent implements OnInit {
	venue: Venue;
	order: Order;
	slots: Moment[] = [];
	loading = false;
	selectedSlot: Moment;

	constructor(
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		order: Order
	): Promise<Moment> {
		const modal = await modalCtrl.create({
			component: SlotsModalComponent,
			componentProps: {
				venue,
				order
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const detail = await modal.onDidDismiss();
		return detail.data;
	}

	ngOnInit() {
		this.loadSlots();
	}

	async loadSlots() {
		this.slots = [];
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot =>
				moment(slot.time)
			);
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(
					this.translate.instant('checkout_modal.no_slots'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('checkout_modal.time_error'),
				null,
				{
					duration: 2000
				}
			);
			console.error(e);
		}
		this.loading = false;
	}

	close() {
		this.modalCtrl.dismiss();
	}

	isSlotFull(slot: Slot): boolean {
		if (!this.venue || !this.venue.slot) {
			return true;
		}
		return isSlotFullByOrder(this.venue.slot, slot, this.order);
	}

	select(slot: Moment) {
		this.modalCtrl.dismiss(slot);
	}
}
