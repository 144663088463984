import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {OrderListAction} from '../../enums/OrderListAction';
import {
	DeleteCartItemModalComponent,
	DeleteCartItemModalComponentResult
} from '../delete-cart-item-modal/delete-cart-item-modal.component';
import {ArticleDetailsModalComponent} from '../article-details-modal/article-details-modal.component';
import moment from 'moment';
import {HomePage} from '../../pages/home/home.page';
import {CheckoutModalComponent} from '../checkout-modal/checkout-modal.component';
import {
	LoginOrderModalComponent,
	LoginOrderModalResult
} from '../login-order-modal/login-order-modal.component';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {AnalyticsService} from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {
	maxFskOfOrder,
	sendOrder,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {MenuPage} from 'src/app/pages/menu/menu.page.component';

@Component({
	selector: 'app-order-content',
	templateUrl: './order-content.component.html',
	styleUrls: ['./order-content.component.scss']
})
export class OrderContentComponent
	extends RepositoryDirective
	implements OnInit
{
	moment = moment;

	isValid = false;
	loading = false;
	maxFsk = 0;
	@Input() isMenuPage = false;

	constructor(
		public repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private translate: TranslateService,
		private analytics: AnalyticsService
	) {
		super(repository);
		this.validate();
	}

	// tslint:disable-next-line:variable-name
	private _maxFskChecked = false;

	get maxFskChecked(): boolean {
		return this._maxFskChecked;
	}

	set maxFskChecked(value: boolean) {
		this._maxFskChecked = value;
		this.validate();
	}

	private _openCheckout = false;

	@Input()
	set openCheckout(value) {
		this._openCheckout = value;
		this.validate();
	}

	get hasArticles(): boolean {
		return this.order.orderedArticles.length > 0;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
	}

	onVenue() {
		super.onVenue();
		this.validate();
	}

	async onAction(index: number, event: OrderListAction) {
		switch (event) {
			case OrderListAction.add:
				this.order.orderedArticles[index].quantity += 1;
				break;
			case OrderListAction.delete:
				const result = await DeleteCartItemModalComponent.show(this.modalCtrl);
				switch (result) {
					case DeleteCartItemModalComponentResult.POSITIVE:
						this.order.orderedArticles.splice(index, 1);
						break;
					case DeleteCartItemModalComponentResult.NEGATIVE:
						break;
				}
				break;
			case OrderListAction.edit:
				const editedArticleGroup = await ArticleDetailsModalComponent.edit(
					this.modalCtrl,
					this.order.orderedArticles[index].article,
					this.order.orderedArticles[index].groups,
					this.order.currency,
					index,
					true
				);
				if (editedArticleGroup !== null) {
					this.order.orderedArticles[index] = editedArticleGroup;
					this.repository.order.emit(this.order);
				}
				return;
			case OrderListAction.remove:
				if (this.order.orderedArticles[index].quantity > 1) {
					this.order.orderedArticles[index].quantity -= 1;
				} else {
					await this.onAction(index, OrderListAction.delete);
				}
				break;
		}
		this.repository.order.emit(this.order);
	}

	onOrder() {
		super.onOrder();
		if (
			this.order &&
			this.order.orderedArticles &&
			this.order.orderedArticles.length > 0
		) {
			this.analytics.orderHasProducts();
		}
		this.validate();
	}

	async sendOrder() {
		if (!this.isValid || this.loading) {
			return;
		}
		if (!this.customer.isAnonymous) {
			this.order.preorder.email = this.customer.email;
			await this.openCheckoutModal();
			return;
		}
		const result = await LoginOrderModalComponent.show(this.modalCtrl);
		if (!result) {
			this.loading = false;
			return;
		}
		switch (result) {
			case LoginOrderModalResult.PROCEED:
				await this.openCheckoutModal();
				return;
			case LoginOrderModalResult.LOGIN:
				await sleep(300);
				await SignInPage.navigate(this.router, true);
				return;
		}
	}

	async openCheckoutModal() {
		if (!this.isValid) {
			return;
		}
		this.loading = true;
		const result = await CheckoutModalComponent.show(this.modalCtrl);
		if (!result) {
			this.loading = false;
			return;
		}
		this.order.orderAt = result.orderAt;
		this.order.preorder = result.preorder;
		this.repository.order.emit(this.order);
		try {
			await sendOrder(
				this.router,
				this.modalCtrl,
				this.translate,
				this.repository,
				this.order,
				0,
				loading => (this.loading = loading),
				this.snackbarCtrl
			);
		} catch (e) {
			console.log(JSON.parse(JSON.stringify(e)));
		}
	}

	validate() {
		if (!this.order) {
			this.isValid = false;
			return;
		}
		this.maxFsk = this.repository.order == null ? 0 : maxFskOfOrder(this.order);
		this.isValid =
			this.order != null &&
			this.order.orderedArticles.length > 0 &&
			OrderUtils.orderTotalPrice(this.order, true, true) > 0 &&
			(this.maxFsk === 0 || this.maxFskChecked);
		if (this.isValid && this._openCheckout) {
			this.openCheckoutModal();
			this._openCheckout = false;
		}
	}

	totalPrice() {
		return OrderUtils.formattedOrderTotalPrice(this.order, true, true);
	}

	home() {
		HomePage.navigate(this.router);
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}
}
