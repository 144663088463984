import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';

@Component({
	selector: 'app-article-option-group-matrix',
	templateUrl: './article-option-group-matrix.component.html',
	styleUrls: ['./article-option-group-matrix.component.scss']
})
export class ArticleOptionGroupMatrixComponent implements OnInit {
	@Output() selection = new EventEmitter<ArticleOption[]>();
	options: ArticleOption[] = [];
	rowLabels: number[] = [];
	// tslint:disable-next-line:variable-name
	private _selected: ArticleOption[] = [];

	constructor() {}

	// tslint:disable-next-line:variable-name
	private _optionGroup: OptionGroup;

	get optionGroup(): OptionGroup {
		return this._optionGroup;
	}

	@Input()
	set optionGroup(value: OptionGroup) {
		if (this._optionGroup == null || this._optionGroup._id !== value._id) {
			this._optionGroup = value;
			if (this._optionGroup == null) {
				this.options = [];
			} else {
				this.options = this.optionGroup.articles.map(article => {
					const articleOption = new ArticleOption();
					articleOption.group = this.optionGroup._id;
					articleOption.article = article;
					articleOption.quantity = 1;
					return articleOption;
				});
			}
		}
	}

	ngOnInit() {
		if (this._selected.length === this.optionGroup.limit) {
			return;
		}
		this._selected.splice(0, this._selected.length);
		for (let i = 0; i < this.optionGroup.limit; i++) {
			this.rowLabels.push(i + 1);
			this._selected.push(null);
		}
	}

	isSelected(index: number, option: ArticleOption): number {
		const selectedOption = this._selected[index - 1];
		return selectedOption != null &&
			selectedOption.group === option.group &&
			selectedOption.article._id === option.article._id
			? 1
			: 0;
	}

	select(index: number, articleOption: ArticleOption) {
		this._selected[index - 1] = articleOption;
		this.triggerSelection();
	}

	triggerSelection() {
		const selectedOptions = [];
		for (const option of this.options) {
			const count = this._selected
				.filter(value => value != null)
				.filter(
					value =>
						value.article._id === option.article._id &&
						value.group === option.group
				)
				.map(value => value.quantity)
				.reduce(
					(previousValue, currentValue) => previousValue + currentValue,
					0
				);
			if (count > 0) {
				const articleOption = new ArticleOption();
				articleOption.article = option.article;
				articleOption.quantity = count;
				articleOption.group = option.group;
				selectedOptions.push(articleOption);
			}
		}
		this.selection.emit(selectedOptions);
	}
}
