import {Component, Input, OnInit} from '@angular/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {PopoverController} from '@ionic/angular';
import {MenuPopoverComponent} from '../menu-popover/menu-popover.component';
import {Router} from '@angular/router';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {MySpacePage} from '../../pages/my-space/my-space.page';
import {MenuPage} from '../../pages/menu/menu.page.component';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {HomePage} from '../../pages/home/home.page';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends RepositoryDirective implements OnInit {
	@Input() showLogo = true;
	open: boolean = false;

	constructor(
		public repository: RepositoryService,
		private popoverCtrl: PopoverController,
		private router: Router
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	async presentPopover(ev: any) {
		console.log(ev);
		const popover = await this.popoverCtrl.create({
			component: MenuPopoverComponent,
			event: ev,
			cssClass: 'menu-popover',
			translucent: true,
			showBackdrop: false
		});
		if (popover) {
			if (this.open) {
				popover.onWillDismiss().then(() => {
					this.open = false;
				});
			} else {
				await popover.present();
				this.open = true;
			}
			await popover.onDidDismiss().then(() => (this.open = false));
		}
	}

	closePopover() {
		this.popoverCtrl.dismiss();
		this.open = false;
	}

	navigateHome() {
		if (!this.venue) {
			HomePage.navigate(this.router);
			return;
		}
		if (!this.order) {
			this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
		}
		MenuPage.navigate(this.router);
	}

	handleUser() {
		if (this.customer.isAnonymous) {
			SignInPage.navigate(this.router);
		} else {
			MySpacePage.navigate(this.router);
		}
	}
}
