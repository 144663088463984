import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page.component';
import {OrderPage} from '../order/order.page';
import {CategoryPage} from '../category/category.page';
import {AppComponent} from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';

@Component({
	selector: 'app-menu-extra',
	templateUrl: './menu-extra.page.html',
	styleUrls: ['./menu-extra.page.scss']
})
export class MenuExtraPage extends RepositoryDirective implements OnInit {
	static url = 'menu-extra';

	article: Article;
	selectedOptions: ArticleOption[];
	menuOptionGroup: OptionGroup;
	articleOptions: ArticleOption[];
	indexInOrder = -1;
	isValid = false;
	redirectToOrder = false;

	constructor(
		private router: Router,
		public repository: RepositoryService,
		private snackbarCtrl: MatSnackBar
	) {
		super(repository);
		const special =
			this.router.getCurrentNavigation().extras.queryParams.special;
		this.article = special.article;
		this.selectedOptions = special.selectedOptions;
		this.menuOptionGroup = special.menuOptionGroup;
		this.indexInOrder = special.indexInOrder;
		this.redirectToOrder = special.redirectToOrder;
		this.articleOptions = this.menuOptionGroup.articles
			.filter(it => it.visible)
			.map(value => {
				const articleOption = new ArticleOption();
				articleOption.article = value;
				articleOption.quantity = 1;
				articleOption.group = this.menuOptionGroup._id;
				return articleOption;
			});

		this.validate();
	}

	static navigate(
		router: Router,
		article: Article,
		menuOptionGroup: OptionGroup,
		selectedOptions: ArticleOption[],
		indexInOrder: number,
		redirectToOrder: boolean = false
	) {
		return router.navigate([MenuExtraPage.url], {
			queryParams: {
				special: {
					article,
					selectedOptions,
					menuOptionGroup,
					indexInOrder,
					redirectToOrder
				}
			},
			skipLocationChange: true
		});
	}

	isSelected(articleOption: ArticleOption): boolean {
		return this.selectedOptions.indexOf(articleOption) >= 0;
	}

	toggle(articleOption: ArticleOption) {
		const index = this.selectedOptions.indexOf(articleOption);
		if (index >= 0) {
			this.selectedOptions.splice(index, 1);
		} else if (
			this.menuOptionGroup.limit === 0 ||
			this.selectedOptions.filter(
				value => value.group === this.menuOptionGroup._id
			).length +
				1 <=
				this.menuOptionGroup.limit
		) {
			this.selectedOptions.push(articleOption);
		} else if (this.menuOptionGroup.limit === 1) {
			OrderUtils.addSingle(this.selectedOptions, articleOption);
		}
		this.validate();
	}

	validate() {
		this.isValid =
			this.selectedOptions.filter(
				value => value.group === this.menuOptionGroup._id
			).length > 0;
	}

	upgrade() {
		this.validate();
		if (this.isValid) {
			this.addToOrder();
		} else {
			this.snackbarCtrl.open('Vergessen etwas auszusuchen?', null, {
				duration: 2000
			});
		}
	}

	decline() {
		this.selectedOptions = this.selectedOptions.filter(
			value => value.group !== this.menuOptionGroup._id
		);
		this.addToOrder();
	}

	addToOrder() {
		if (
			this.indexInOrder >= 0 &&
			this.indexInOrder < this.order.orderedArticles.length
		) {
			this.order.orderedArticles[this.indexInOrder].article = this.article;
			this.order.orderedArticles[this.indexInOrder].groups =
				this.selectedOptions;
			this.repository.order.emit(this.order);
			if (this.redirectToOrder) {
				OrderPage.navigate(this.router);
			} else {
				if (!AppComponent.largeScreen) {
					MenuPage.navigate(this.router);
				} else {
					CategoryPage.navigate(this.router);
				}
			}
		} else {
			const index = this.order.orderedArticles.findIndex(orderedArticle => {
				return (
					orderedArticle.article._id === this.article._id &&
					this.selectedOptions.length === orderedArticle.groups.length &&
					this.selectedOptions
						.map(
							option =>
								orderedArticle.groups.findIndex(orderedOption => {
									return (
										option.article._id === orderedOption.article._id &&
										option.quantity === orderedOption.quantity &&
										option.dependency === orderedOption.dependency &&
										option.dependsOn === orderedOption.dependsOn &&
										option.dependencyNumber === orderedOption.dependencyNumber
									);
								}) >= 0
						)
						.reduce(
							(previousValue, currentValue) => previousValue && currentValue,
							true
						)
				);
			});
			console.log({index});
			if (index >= 0) {
				this.order.orderedArticles[index].quantity++;
			} else {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.quantity = 1;
				articleGroup.groups = this.selectedOptions;
				this.order.orderedArticles.push(articleGroup);
			}
			this.repository.order.emit(this.order);
			if (this.redirectToOrder) {
				OrderPage.navigate(this.router);
			} else {
				if (!AppComponent.largeScreen) {
					MenuPage.navigate(this.router);
				} else {
					CategoryPage.navigate(this.router);
				}
			}
		}
	}

	ngOnInit() {
		super.ngOnInit();
	}
}
