import {Component, OnInit, ViewChild} from '@angular/core';
import {
	IonContent,
	IonSelect,
	IonSlides,
	ModalController,
	PickerController
} from '@ionic/angular';
import * as moment from 'moment';
import {Moment} from 'moment';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OrderPage} from 'src/app/pages/order/order.page';
import {TosPage} from 'src/app/pages/tos/tos.page';
import {PrivacyPage} from 'src/app/pages/privacy/privacy.page';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {
	getPrice,
	getSlotsByOrder,
	isVenueOpen,
	numberToCurrency,
	sleep
} from 'src/smoothr-web-app-core/utils/utils';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import Preorder from '../../../smoothr-web-app-core/models/Preorder';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';

@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['./checkout-modal.component.scss']
})
export class CheckoutModalComponent
	extends RepositoryDirective
	implements OnInit
{
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	orderAtError: string;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover'
	};
	isVenueOpen = false;
	isValid = false;
	slots: Moment[] = [];
	numberToCurrency = numberToCurrency;

	@ViewChild(IonContent, {static: false}) content;
	@ViewChild(IonSelect, {static: false}) select;
	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false
	};
	isOverviewPage = false;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	static async show(
		modalCtrl: ModalController
	): Promise<{asap: boolean; orderAt: Moment; preorder: Preorder}> {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			showBackdrop: true,
			cssClass: 'checkout-modal-full',
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(100);
		return result;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.beginCheckout(this.order);
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.loadSlots();
	}

	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot =>
				moment(slot.time)
			);
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(
					this.translate.instant('checkout_modal.no_slots'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('checkout_modal.time_error'),
				null,
				{
					duration: 2000
				}
			);
			console.error(e);
		}
		this.loading = false;
	}

	onOrder() {
		super.onOrder();
		if (OrderUtils.isPreorder(this.order)) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName =
						this.repository._previousPreorder.companyName;
				}
			}
		}
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (
					!this.order.preorder.number &&
					this.order.preorder.street === this.customer.street
				) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	validate(): boolean {
		this.nameError = null;
		this.emailError = null;
		this.phoneError = null;
		this.orderAtError = null;
		if (!this.venue || !this.order || !this.order.preorder) {
			this.snackbarCtrl.open(
				this.translate.instant('errors.data_error'),
				null,
				{
					duration: 2000
				}
			);
			this.isValid;
			return false;
		}
		if (!this.order.preorder.name || this.order.preorder.name.length < 3) {
			this.nameError = this.translate.instant('errors.name');
			this.snackbarCtrl.open(this.nameError, null, {
				duration: 2000
			});
			return false;
		}
		if (
			!this.order.preorder.email ||
			!validator.isEmail(this.order.preorder.email)
		) {
			this.emailError = this.translate.instant('errors.email');
			this.snackbarCtrl.open(this.emailError, null, {
				duration: 2000
			});
			return false;
		}
		if (!ValidationUtils.validatePhone(this.order.preorder.phone)) {
			this.phoneError = this.translate.instant('errors.phone');
			this.snackbarCtrl.open(this.phoneError, null, {
				duration: 2000
			});
			return false;
		}
		if (!this.order.orderAt) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000
			});
			return false;
		}
		this.order.orderAt = moment(this.order.orderAt);
		if (this.order.orderAt.isBefore(moment())) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000
			});
			return false;
		}
		return true;
	}

	async close() {
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			asap: this.order.asap
		};
		if (!(await this.validate())) {
			await sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(
				el => {
					return (
						el.className
							.split(' ')
							.findIndex(clazz => clazz === 'input-error') >= 0
					);
				}
			);
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	hiddenInCart(article: Article): boolean {
		return (
			article.tags &&
			article.tags.find(
				tag => tag.identifier === 'hide_cart' || tag.identifier === 'empty'
			) !== undefined
		);
	}

	async onButtonClick() {
		if (!(await this.validate())) {
			return;
		}
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}

	async toOverview() {
		const result = await this.validate();
		if (!result) {
			this.isOverviewPage = false;
			return;
		}
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}

	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}

	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}

	priceOfOption(
		articleGroup: ArticleGroup,
		articleOption: ArticleOption
	): number {
		const price = getPrice(
			articleOption.article,
			OrderType.PREORDER,
			this.order.preorder.type
		);
		if (
			OrderUtils.isBogoOrFreeArticlePromo(this.order) &&
			articleGroup.isPromo
		) {
			return 0;
		}
		return price * articleOption.quantity;
	}

	async openTos() {
		await this.modalCtrl.dismiss();
		TosPage.navigate(this.router);
	}

	async openPrivacy() {
		await this.modalCtrl.dismiss();
		PrivacyPage.navigate(this.router);
	}

	async goToOrder() {
		await this.modalCtrl.dismiss();
		OrderPage.navigate(this.router);
	}
}
