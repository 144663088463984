import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {DisplayMode} from '../../../enums/DisplayMode';
import {IonContent, ModalController} from '@ionic/angular';
import {PushModalComponent} from '../../components/push-modal/push-modal.component';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import Article from 'src/smoothr-web-app-core/models/Article';
import {isActiveForOrder} from '../../../smoothr-web-app-core/utils/utils';

const LOCAL_STORAGE_KEY_CATEGORY_ID = 'category-page-category-id';

@Component({
	selector: 'app-category',
	templateUrl: './category.page.html',
	styleUrls: ['./category.page.scss']
})
export class CategoryPage extends RepositoryDirective implements OnInit {
	static url = 'category';
	private static openCheckout = false;
	category: ArticleCategory;
	dm = DisplayMode;
	@ViewChild(IonContent, {static: true}) content: IonContent;
	@ViewChild(ToOrderButtonComponent, {static: true})
	toOrderButton: ToOrderButtonComponent;
	categories: ArticleCategory[] = [];
	openCheckout = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public repository: RepositoryService,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	private static get categoryId(): string {
		return localStorage.getItem(LOCAL_STORAGE_KEY_CATEGORY_ID);
	}

	private static set categoryId(value) {
		localStorage.setItem(LOCAL_STORAGE_KEY_CATEGORY_ID, value);
	}

	static navigate(
		router: Router,
		category?: string,
		openCheckout: boolean = false
	) {
		CategoryPage.categoryId = category;
		CategoryPage.openCheckout = openCheckout;
		router.navigateByUrl(CategoryPage.url);
	}

	ionViewDidEnter() {
		if (CategoryPage.openCheckout) {
			this.openCheckout = true;
			CategoryPage.openCheckout = false;
		}
		this.reloadCategories();
	}

	index(): number {
		return this.categories.indexOf(this.category);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	changeCategory(category: string) {
		const index = this.categories.findIndex(
			(categoryInner: ArticleCategory) => categoryInner._id === category
		);
		this.category = this.categories[index];
		CategoryPage.categoryId = category;
		this.content.scrollToTop(100);
	}

	onVenue() {
		super.onVenue();
		this.reloadCategories();
	}

	async onArticleSelected(articleGroup: ArticleGroup) {
		const index = this.order.orderedArticles.findIndex(orderedArticle => {
			return (
				orderedArticle.article._id === articleGroup.article._id &&
				articleGroup.groups.length === orderedArticle.groups.length &&
				articleGroup.groups
					.map(
						option =>
							orderedArticle.groups.findIndex(orderedOption => {
								return (
									option.article._id === orderedOption.article._id &&
									option.quantity === orderedOption.quantity &&
									option.dependency === orderedOption.dependency &&
									option.dependsOn === orderedOption.dependsOn &&
									option.dependencyNumber === orderedOption.dependencyNumber
								);
							}) >= 0
					)
					.reduce(
						(previousValue, currentValue) => previousValue && currentValue,
						true
					)
			);
		});
		if (index >= 0) {
			this.order.orderedArticles[index].quantity++;
		} else {
			this.order.orderedArticles.push(articleGroup);
		}
		await PushModalComponent.show(this.modalCtrl, 'Erfolgreich hinzugefügt');
		this.repository.order.emit(this.order);
		/*MenuPage.navigate(this.router);*/
	}

	next() {
		const nextIndex = this.index() + 1;
		if (nextIndex < this.categories.length) {
			this.category = this.categories[nextIndex];
			CategoryPage.categoryId = this.category._id;
			this.content.scrollToTop(100);
		}
	}

	prev() {
		const prevIndex = this.index() - 1;
		if (prevIndex >= 0) {
			this.category = this.categories[prevIndex];
			CategoryPage.categoryId = this.category._id;
			this.content.scrollToTop(100);
		}
	}

	async reloadCategories() {
		if (!this.venue || !this.order) {
			this.categories = [];
			this.category = null;
			return;
		}
		const allArticles: Article[] = [];
		this.venue.articleCategories.forEach(cat =>
			allArticles.push(...cat.articles)
		);
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					return isActiveForOrder(allArticles, article, this.order);
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
		this.category = this.categories.find(
			cat => cat._id === CategoryPage.categoryId
		);

		if (!this.category) {
			this.category = this.categories[0];
		}
	}
}
