import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

export enum LoginOrderModalResult {
	LOGIN = 'login',
	PROCEED = 'proceed'
}

@Component({
	selector: 'app-login-order-modal',
	templateUrl: './login-order-modal.component.html',
	styleUrls: ['./login-order-modal.component.scss']
})
export class LoginOrderModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(
		modalCtrl: ModalController
	): Promise<LoginOrderModalResult> {
		const modal = await modalCtrl.create({
			cssClass: 'auto-size reg-modal',
			component: LoginOrderModalComponent,
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		if (result.data == null) {
			return null;
		}
		return result.data.action;
	}

	ngOnInit() {}

	login() {
		this.modalCtrl.dismiss({
			action: LoginOrderModalResult.LOGIN
		});
	}

	proceed() {
		this.modalCtrl.dismiss({
			action: LoginOrderModalResult.PROCEED
		});
	}
}
